<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">每年体系树的维护和更新</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        企业的管理不会一成不变，所以体系树构建后还需要定期维护，我们提供这个服务，保持你的体系树与企业共同进步
      </p>
      <br />

      <p class="PTitle">解决客户的什么问题：</p>
      <p class="SmallP">
        体系变化、业务变化、内外部环境变化后，体系文件没有及时跟进
        某份文件更改后，相关文件没有同步更新
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        提供文件构建辅导和培训，可在线可线下
        <br />
        教你如何搭建体系树
        <br />
        提供软件平台，将体系树录入系统中，同步更新
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>